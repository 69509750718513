// src/BuyNowButton.js

import React from 'react';

/**
 * BuyNowButton Component
 *
 * Props:
 * - href: The URL to navigate to when the button is clicked.
 * - logoSrc: The source path for the logo image.
 * - altText: Alternative text for the logo image.
 * - buttonClass: Additional CSS classes for styling the button.
 */
const BuyNowButton = ({ vendor, domainName, logoSrc, altText, buttonClass }) => {
  

  // Build the href based on the vendor
  let href = '#';
  if (vendor === 'namecheap') {
    const affiliateId = process.env.REACT_APP_NAMESCHEAP_AFFILIATE_ID;
    href = `http://www.anrdoezrs.net/links/${affiliateId}/type/dlg/https://www.namecheap.com/domains/registration/results.aspx?domain=${domainName}`;
  } else if (vendor === 'godaddy') {
    href = `https://www.godaddy.com/domainsearch/find?domainToCheck=${domainName}`;
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`btn ${buttonClass}`}
    >
      <img
        src={logoSrc}
        alt={altText}
        style={{ width: '20px', marginRight: '5px' }}
      />
      Buy Now
    </a>
  );
};

export default BuyNowButton;