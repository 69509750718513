// src/pages/PrivacyPolicy.js

import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS for styling

const PrivacyPolicy = () => {
  return (
    <div className="container my-5 privacy-policy">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong> September 26, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to <strong>Baby Names For Sale</strong> ("we," "us," or "our"). We prioritize your privacy and data security. This Privacy Policy explains how we collect, use, and protect your information when you use our application <strong>Baby Names For Sale</strong> ("the App").
      </p>
      <p>
        By using the App, you consent to the practices described in this policy.
      </p>

      <h2>2. Data Collection</h2>
      <h3>2.1 Types of Data We Collect</h3>
      <ul>
        <li><strong>Personal Information:</strong> First Name, Last Name, Gender, Birth Year, Last Name Entered for Domain Search.</li>
        <li><strong>Technical Data:</strong> IP Address, Browser Information, Device Information, Usage Data (e.g., pages visited, time spent).</li>
      </ul>

      <h3>2.2 How We Collect Data</h3>
      <ul>
        <li><strong>Search Forms:</strong> Information entered when using our domain search feature.</li>
        <li><strong>Automated Tracking:</strong> Data collected through your interactions with the App.</li>
        <li><strong>Cookies:</strong> Used to enhance your experience and gather usage data.</li>
      </ul>

      <h2>3. Purpose of Data Collection</h2>
      <ul>
        <li><strong>Service Delivery:</strong> Providing domain name search functionality.</li>
        <li><strong>Analytics:</strong> Generating insights like the "Top Names This Week" section.</li>
        <li><strong>Improvement:</strong> Enhancing App efficiency and developing future features.</li>
        <li><strong>User Experience:</strong> Personalizing and improving your interaction with the App.</li>
      </ul>

      <h2>4. Data Usage</h2>
      <ul>
        <li><strong>Logging Searches:</strong> To analyze popular names.</li>
        <li><strong>Feature Development:</strong> Utilizing form data for creating and enhancing features.</li>
        <li><strong>Internal Analytics:</strong> Understanding user preferences to improve functionality.</li>
      </ul>

      <h2>5. Data Sharing and Disclosure</h2>
      <h3>5.1 Third-Party Services</h3>
      <p>We only share your data with trusted third parties for hosting and management purposes:</p>

      <h3>5.2 Legal Obligations</h3>
      <p>We may disclose your data to comply with legal requirements or protect our rights.</p>

      <h2>6. Data Storage and Security</h2>
      <h3>6.1 Data Storage</h3>
      <p>Your data is stored on Supabase servers located in <strong>the United States</strong>. Access is restricted to authorized personnel only.</p>
      
      <h3>6.2 Security Measures</h3>
      <ul>
        <li><strong>Encryption:</strong> All data is encrypted both at rest and in transit.</li>
        <li><strong>Access Controls:</strong> Preventing unauthorized access through strict protocols.</li>
        <li><strong>Regular Audits:</strong> Ensuring ongoing security and data integrity.</li>
      </ul>

      <h2>7. Data Retention</h2>
      <p>We retain your personal data for up to <strong>two (2) years</strong> from your last interaction with the App. Post this period, data is securely deleted unless required otherwise by law.</p>

      <h2>8. User Rights and Control</h2>
      <h3>8.1 Your Rights</h3>
      <ul>
        <li><strong>Access:</strong> Request access to your personal data.</li>
        <li><strong>Correction:</strong> Request updates to inaccurate or incomplete data.</li>
        <li><strong>Deletion:</strong> Request deletion of your personal data.</li>
        <li><strong>Data Portability:</strong> Request your data in a structured format.</li>
      </ul>
      
      <h3>8.2 Exercising Your Rights</h3>
      <p>Contact us at <a href="mailto:privacy@babynamesforsale.com">privacy@babynamesforsale.com</a> to exercise any of these rights.</p>

      <h2>9. Cookies and Tracking Technologies</h2>
      <h3>9.1 Use of Cookies</h3>
      <ul>
        <li><strong>Session Management:</strong> Maintaining your logged-in status.</li>
        <li><strong>Analytics:</strong> Understanding user interactions to improve the App.</li>
      </ul>
      
      <h3>9.2 Managing Cookies</h3>
      <p>You can disable cookies through your browser settings. Note that this may affect App functionality.</p>

      <h2>10. Children's Privacy</h2>
      <p>The App is not intended for children under thirteen (13). We do not knowingly collect data from children under this age. If discovered, such data will be promptly deleted.</p>

      <h2>11. International Data Transfers</h2>
      <p>Your data is processed in <strong>the United States</strong>. We comply with international data protection laws, including the <a href="https://gdpr-info.eu/" target="_blank" rel="noopener noreferrer">GDPR</a> where applicable.</p>

      <h2>12. Legal Basis for Data Processing</h2>
      <ul>
        <li><strong>Legitimate Interests:</strong> Providing and improving our services.</li>
        <li><strong>Consent:</strong> Obtaining your consent for specific data processing activities.</li>
      </ul>

      <h2>13. Changes to This Privacy Policy</h2>
      <p>We may update this policy periodically. Changes will be posted here with the new revision date. We encourage regular review to stay informed.</p>

      <h2>14. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:privacy@babynamesforsale.com">privacy@babynamesforsale.com</a></li>
        <li><strong>Mailing Address:</strong> PO Box 54 Centerville, Indiana 47330</li>
        <li><strong>Phone:</strong> 765.595.0408</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
