// src/pages/About.js

import React from 'react';

const About = () => {
  return (
    <div className="container my-5">
      <h1>About Us</h1>
      <p>
        This is an application to help expecting parents find available domain names for their baby's name.
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default About;
