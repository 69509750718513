// src/pages/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="container text-center my-5">
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <Link to="/" className="btn btn-primary">Go to Home</Link>
  </div>
);

export default NotFound;
