// src/components/Footer.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram
} from 'react-icons/fa';
import './Footer.css'; // Import the CSS for styling

const Footer = () => {
  // State to manage the expansion of the footer on mobile


  return (
    <footer className="footer bg-light text-lg-start">
      <div className="container p-4">
        {/* Social Media Icons */}
        <section className="mb-4 text-center">
          <a
            href="https://www.facebook.com/babynamesforsale"
            className="btn btn-outline-primary btn-floating m-1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://twitter.com/babynames4sale"
            className="btn btn-outline-primary btn-floating m-1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.instagram.com/babynamesforsale"
            className="btn btn-outline-primary btn-floating m-1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
        </section>

      
        {/* Collapsible Footer Content */}
        <section
          className="footer-content d-lg-block"
          id="footerContent"
        >
          {/* Links */}
          <section className="text-left mb-4">
            <Link to="/privacy-policy" className="text-dark me-4">
              Privacy Policy
            </Link>
          </section>



          {/* Affiliate Disclaimer */}
          <section className="mt-4 text-left">
            <p className="text-muted">
              By purchasing domains through the links above, you support our
              website at no additional cost to you.
            </p>
          </section>
        </section>
      </div>

  
   {/* Copyright */}
   <div className="text-center p-3 bg-light">
        © {new Date().getFullYear()} Baby Names For Sale
      </div>
    </footer>
  );
};

export default Footer;
