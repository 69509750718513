// src/pages/Home.js

import React from 'react';
import TopSearchedNames from '../components/TopSearchedNames';
import BuyNowButton from '../components/BuyNowButton';
import CreatableSelect from 'react-select/creatable';
import animatedComponents from 'react-select/animated';

const Home = ({
  year,
  setYear,
  availableYears,
  gender,
  setGender,
  names,
  selectedName,
  setSelectedName,
  lastName,
  setLastName,
  handleDomainCheck,
  isLoading,
  domainResults,
  error,
  successMessage,
  queryError,
  queryLoading,
  handleNameChange,
  handleCreate,
  limit,
  middleName,
  setMiddleName
}) => {
  // Include the content you had in your original return statement
  return (
    <div className="container my-5">
      
      <div className="row">
        <div className="col-md-8">
          {/* Display Errors */}
          {error && <div className="alert alert-danger">{error}</div>}
          {queryError && (
            <div className="alert alert-danger">Error: {queryError.message}</div>
          )}

          {/* Display Success Message */}
          {successMessage && (
            <div className="alert alert-success">{successMessage}</div>
          )}

          {/* Loading Indicator for Names */}
          {queryLoading && (
            <div className="alert alert-info">Loading names...</div>
          )}

          {/* Handle No Names Found */}
          {!queryLoading && names.length === 0 && (
            <div className="alert alert-warning">
              No names available for the selected year and gender.
            </div>
          )}

          {/* Year Selector */}
          <div className="form-group mb-3">
            <label htmlFor="yearSelect">Select Year:</label>
            <select
              id="yearSelect"
              className="form-control"
              value={year || ''}
              onChange={(e) => setYear(parseInt(e.target.value, 10))}
              disabled={availableYears.length === 0 || queryLoading}
            >
              <option value="">Select Year</option>
              {availableYears.length > 0 ? (
                availableYears.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))
              ) : (
                <option value="">No Years Available</option>
              )}
            </select>
          </div>

          {/* Gender Selector */}
          <div className="form-group mb-3">
            <label htmlFor="genderSelect">Select Gender:</label>
            <select
              id="genderSelect"
              className="form-control"
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              } }
              disabled={queryLoading}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          {/* Name Combo Box */}
          <div className="form-group mb-3">
            <label htmlFor="nameSelect">Select or Enter a Name:</label>
            <CreatableSelect
              components={animatedComponents}
              isClearable
              isSearchable
              options={names}
              value={selectedName}
              onChange={handleNameChange}
              onCreateOption={handleCreate}
              placeholder="Type or select a name..."
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: '#ced4da',
                  '&:hover': {
                    borderColor: '#86b7fe',
                  },
                }),
              }} />
          </div>
          {/* Middle Name Input */}
      <div className="form-group mb-3">
        <label htmlFor="middleNameInput">Enter Middle Name (Optional):</label>
        <input
          type="text"
          id="middleNameInput"
          className="form-control"
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
          placeholder="e.g., Anne"
        />
      </div>
          {/* Last Name Input */}
          <div className="form-group mb-3">
            <label htmlFor="lastNameInput">Enter Your Last Name:</label>
            <input
              type="text"
              id="lastNameInput"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="e.g., Smith" />
          </div>

          {/* Domain Checker */}
          <button
            className="btn btn-primary my-3"
            onClick={handleDomainCheck}
            disabled={isLoading}
          >
            {isLoading ? 'Checking...' : 'Check Domain Availability'}
          </button>

         </div>
          <div className="col-md-4">
          {/* New Top Searched Names Section */}
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <TopSearchedNames gender="M" limit={limit} />
            </div>
            <div className="col-lg-6 col-md-12">
              <TopSearchedNames gender="F" limit={limit} />
            </div>
          </div>
        </div>
      </div>
       {/* Display Selected Name */}
       {selectedName && lastName && (
        <div className="alert alert-info">
          You have selected:{' '}
          <strong>
            {selectedName.value}
            {middleName ? ` ${middleName}` : ''} {lastName}
          </strong>
        </div>
      )}

      {/* Display Domain Results */}
      {domainResults.length > 0 && (
        <div>
          <h3>Domain Availability:</h3>
          <ul className="list-group">
            {domainResults.map((domain) => (
              <li
                key={`${domain.fullName}.${domain.extension}`}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>
                  {domain.fullName}.{domain.extension} - {domain.status}
                </span>
                {domain.status === 'Available' && (
                  <div>
                    {/* Namecheap Buy Now Button */}
                    <BuyNowButton
                      vendor="namecheap"
                      domainName={`${domain.fullName}.${domain.extension}`}
                      logoSrc="/logos/namecheap-logo.png"
                      altText="Namecheap Logo"
                      buttonClass="btn-warning"
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Home;
