// src/App.js

import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient'; // Ensure this is correctly configured
import { useQuery } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; // Import your Home component
import About from './pages/About'; // Import the About component
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import the Privacy Policy component
import NotFound from './pages/NotFound'; // Import NotFound
import { Link } from 'react-router-dom';
import Footer from './components/Footer'; // Import Footer


import './App.css'; // Ensure your styles are correctly defined
const limit = process.env.REACT_APP_SEARCHED_NAMES_LIMIT || 5;
// Utility function for mapping gender terms to abbreviations
const mapGenderToAbbreviation = (gender) => {
  const mapping = {
    Male: 'M',
    Female: 'F',
  };
  return mapping[gender] || 'M'; // Defaults to 'M' if undefined
};

function App() {
  
  // State variables
  const startYear = parseInt(process.env.REACT_APP_START_YEAR, 10) || 1970;
  const endYear = parseInt(process.env.REACT_APP_END_YEAR, 10) || 2023;
  const availableYears = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  ).reverse();

  const [year, setYear] = useState(2023);
  const [gender, setGender] = useState('Male'); // Initialize to 'Male'
  const [names, setNames] = useState([]);
  const [lastName, setLastName] = useState('');
  const [selectedName, setSelectedName] = useState(null);
  const [domainResults, setDomainResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [middleName, setMiddleName] = useState(''); // Add this line


  // React Query: Fetch names based on year and gender
  const fetchNames = async ({ queryKey }) => {
    const [, selectedYear, selectedGender] = queryKey;

    // Validate that year is a number
    if (isNaN(selectedYear)) {
      throw new Error('Invalid year provided.');
    }

    // Map full gender term to abbreviation ('Male' -> 'M', 'Female' -> 'F')
    const genderAbbr = mapGenderToAbbreviation(selectedGender);

    const { data, error: supabaseError, count } = await supabase
      .from('names')
      .select('*', { count: 'exact' })
      .eq('Year', selectedYear)
      .eq('Gender', genderAbbr)
      .order('Count', { ascending: false });

    if (supabaseError) {
      console.error('Supabase Error fetching names:', supabaseError);
      throw supabaseError;
    }

    if (!data) {
      console.error('Supabase returned no data for names.');
      throw new Error('No data returned');
    }

    return { data, count };
  };

  const {
    data: queryData,
    error: queryError,
    isLoading: queryLoading,
  } = useQuery(['names', year, gender], fetchNames, {
    enabled: Number.isInteger(year) && !!gender, // Only run the query if year and gender are valid
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Update names when query data changes
  useEffect(() => {
    if (queryData) {
      const nameOptions = queryData.data.map((nameObj, index) => ({
        value: nameObj.Name,
        label: `${index + 1}. ${nameObj.Name}`,
      }));
      setNames(nameOptions);
    }
  }, [queryData]);

  // Function to track the search in the 'searches' table
  const trackSearch = async (firstName, lastName, gender) => {
    try {
      const genderAbbr = mapGenderToAbbreviation(gender); // Convert to 'M' or 'F'

      const { data, error } = await supabase.rpc('increment_search_count', {
        p_first_name: firstName,
        p_last_name: lastName,
        p_gender: genderAbbr
      });

      if (error) {
        console.error('Supabase RPC Error tracking search:', error);
        // Optionally, display an error message to the user
      } else {
        console.log('Search tracked successfully:', data);
        setSuccessMessage('Your search has been recorded.');
        // Log the event to GA
        // Remove the success message after a delay
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (err) {
      console.error('Unexpected Error tracking search:', err);
      // Optionally, handle the error (e.g., show a notification)
    }
  };
  const handleDomainCheck = async () => {
    if (!selectedName || !lastName) {
      alert('Please select a name and enter your last name.');
      return;
    }
  
    // Simple regex to allow only letters and hyphens
    const nameRegex = /^[A-Za-z-]+$/;
    if (!nameRegex.test(lastName)) {
      alert('Please enter a valid last name (letters and hyphens only).');
      return;
    }
  
    const firstName = selectedName.value;
    const middleInitial = middleName ? middleName.charAt(0) : '';
    const fullMiddleName = middleName || '';
  
    // Generate different combinations of the full name
    const nameCombinations = [
      `${firstName}${lastName}`, // FirstNameLastName
    ];
  
    if (middleName) {
      nameCombinations.push(
        `${firstName}${middleInitial}${lastName}`, // FirstNameMiddleInitialLastName
        `${firstName}${fullMiddleName}${lastName}` // FirstNameMiddleNameLastName
      );
    }
  
    setIsLoading(true);
    setDomainResults([]);
    setError('');
    setSuccessMessage('');
  
    console.log(`Checking domains for: ${nameCombinations.join(', ')}`);
  
    try {
      // Define the extensions to check
      const extensionsToCheck = ['com', 'net', 'org']; // Add more as needed
  
      // Prepare domain names to check
      const domainsToCheck = [];
      nameCombinations.forEach((nameCombo) => {
        extensionsToCheck.forEach((ext) => {
          domainsToCheck.push(`${nameCombo}.${ext}`);
        });
      });
  
      // Fetch cached domains from Supabase
      const { data: cachedDomains, error: cacheError } = await supabase
        .from('domains')
        .select('*')
        .in('domain_name', domainsToCheck);
  
      console.log('Fetched cached domains:', cachedDomains);
      console.log('Cache error:', cacheError);
  
      const domainsToCheckViaAPI = [];
      const cachedResults = [];
  
      const now = new Date();
  
      // Determine which domains need to be checked via API
      if (cachedDomains && cachedDomains.length > 0) {
        domainsToCheck.forEach((domainName) => {
          const cachedDomain = cachedDomains.find(
            (cd) => cd.domain_name === domainName
          );
  
          if (cachedDomain) {
            const lastChecked = new Date(cachedDomain.last_checked);
            const diffHours = (now - lastChecked) / (1000 * 60 * 60);
  
            if (
              diffHours < 24 &&
              cachedDomain.status &&
              cachedDomain.status !== 'Error'
            ) {
              // Use cached status
              const [fullName, extension] = domainName.split('.');
              cachedResults.push({
                fullName,
                extension,
                status: cachedDomain.status,
              });
            } else {
              // Needs to be checked via API
              domainsToCheckViaAPI.push(domainName);
            }
          } else {
            // Not in cache, needs to be checked via API
            domainsToCheckViaAPI.push(domainName);
          }
        });
      } else {
        // If no cached domains, need to check all
        domainsToCheckViaAPI.push(...domainsToCheck);
      }
  
      // If all domains are cached and recent
      if (domainsToCheckViaAPI.length === 0) {
        setDomainResults(cachedResults);
        setIsLoading(false);
        return;
      }
  
      // Make API call to check domain statuses
      const response = await fetch(
        `/.netlify/functions/checkDomain?domains=${encodeURIComponent(
          domainsToCheckViaAPI.join(',')
        )}`
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }
  
      const apiData = await response.json();
      console.log('API response data:', apiData);
  
      // Update the 'domains' table with new statuses
      const upsertData = apiData.map((domain) => ({
        domain_name: `${domain.fullName}.${domain.extension}`,
        status: domain.status,
        last_checked: new Date().toISOString(),
      }));
  
      const { data: upserted, error: upsertError } = await supabase
        .from('domains')
        .upsert(upsertData, { onConflict: ['domain_name'] })
        .select();
  
      console.log('Upserted data:', upserted);
      console.log('Upsert error:', upsertError);
  
      if (upsertError) {
        console.error('Supabase Error updating domains:', upsertError);
        throw upsertError;
      }
  
      // Prepare new results from upserted data
      const newResults = upserted.map((domain) => {
        const [fullName, extension] = domain.domain_name.split('.');
        return {
          fullName,
          extension,
          status: domain.status,
        };
      });
  
      // Combine cached and new results
      const combinedResults = [...cachedResults, ...newResults];
  
      setDomainResults(combinedResults);
      await trackSearch(firstName, lastName, gender); // Pass gender here
    } catch (err) {
      console.error('Error checking domain availability:', err);
      setError(
        err.message ||
          'There was an error checking the domain availability. Please try again later.'
      );
    }
  
    setIsLoading(false);
  };
  
  // Custom handler for creating new options in react-select
  const handleNameChange = (selectedOption) => {
    setSelectedName(selectedOption);
  };

  // Handler for when user types a new name
  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setSelectedName(newOption);
  };

  return (
    <Router>
    <div className="App">
      {/* You can add a navigation bar here */}
      {/* Example Navigation */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container">
  <Link className="navbar-brand" to="/">
      <img
        src="/logos/logo-sq.png" // Path to your square logo
        alt="Logo"
        className="header-logo mb-3"
      />
      Baby Domain Names for Sale
      </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav ms-auto">
  <li className="nav-item">
    <Link className="nav-link" to="/">
      Home
    </Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/about">
      About
    </Link>
  </li>
</ul>
    </div>
  </div>
</nav>
{/* Main Content */}
<div className="main-content">

      {/* Define your routes */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              year={year}
              setYear={setYear}
              availableYears={availableYears}
              gender={gender}
              setGender={setGender}
              names={names}
              selectedName={selectedName}
              setSelectedName={setSelectedName}
              lastName={lastName}
              setLastName={setLastName}
              handleDomainCheck={handleDomainCheck}
              isLoading={isLoading}
              domainResults={domainResults}
              error={error}
              successMessage={successMessage}
              queryError={queryError}
              queryLoading={queryLoading}
              handleNameChange={handleNameChange}
              handleCreate={handleCreate}
              limit={limit}
              middleName={middleName} // Add this line
              setMiddleName={setMiddleName} // Add this line
            />
          }
        />
         <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* New Route */}
          <Route path="*" element={<NotFound />} /> {/* Catch-all Route */}
      </Routes>
      </div>
      {/* Footer */}
     
    </div>
    <Footer /> {/* Add Footer here */}
  </Router>
);
}

export default App;
