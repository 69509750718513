// src/components/TopSearchedNames.js

import React from 'react';
import { useQuery } from 'react-query';
import { supabase } from '../supabaseClient';

// Adjusted fetchTopNames function to use stored procedure
const fetchTopNames = async (gender, limit) => {
    const { data, error } = await supabase
      .rpc('get_top_searched_names', { p_gender: gender, p_limit: limit });
  
    if (error) {
      throw error;
    }
  
    return data;
  };


const TopSearchedNames = ({ gender, limit }) => {

  const {
    data: names,
    error,
    isLoading,
  } = useQuery(['topNames', gender], () => fetchTopNames(gender, limit));

  if (isLoading) {
    return <div>Loading top {gender === 'M' ? 'boy' : 'girl'} names...</div>;
  }

  if (error) {
    return <div>Error loading top names: {error.message}</div>;
  }

  return (
    <div>
      <h3>Top {gender === 'M' ? 'Boy' : 'Girl'} Names This Week</h3>
      <ol>
        {names.map((name) => (
          <li key={name.first_name}>
            {name.first_name}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TopSearchedNames;
